export const adminItem = [

    {
      name: '',
      items: [
        {
          name: 'Profile',
          slug: 'admin/profile',
          description: 'Information Profile',
        },
    
      ],
    },
    {
      name: 'SETTINGS',
      items: [
        {
          name: 'User Settings',
          slug: 'admin/getallusers',
          description: 'User Analysis',
        },
        {
          name: 'Announcement',
          slug: 'admin/Announcement',
          description: 'Announcement',
        }
      ],
    }
    
  ];
  